.pop-up .inner .top {
  background-color: #181818;
  padding: 15px 23px;
  position: relative;
}

.pop-up .inner input[type="text"] {
  background-color: #fff;
  border: none;
  border-radius: 3px;
  color: #181818;
  display: block;
  font-size: 14px;
  padding: 10px;
  width: calc(100% - 20px);
}
