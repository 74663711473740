nav .items {
  display: flex;
  position: relative;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

nav .items.left {
  margin: auto 0;
  text-align: left;
}

nav .items.left .item {
  border-bottom: 1px solid red;
}

nav .items.right .item.account {
  display: flex;
  margin: auto 0 auto 13px;
}

nav .items.left a:hover {
  border-bottom: 0.5px solid #0ad60a;
  transition: 0.5s;
}

nav .items.right {
  background-color: transparent;
  margin: auto 0 auto auto;
  text-align: right;
}

nav .items.right:hover {
  cursor: pointer;
}

nav .items.right .item .profile-circle {
  background-color: #282828;
  border-radius: 50%;
  cursor: pointer;
  height: 40px;
  margin: auto 0;
  overflow: hidden;
  position: relative;
  width: 40px;
}

nav .items.right .item:hover .profile-circle {
  background-color: #383838;
  transition: 0.2s;
}

nav .items.right .item.add-user .profile-circle .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nav .items.right .item.account .profile-icon {
  background-image: url("../../assets/svgs/person.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 28px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
}

nav .items.right:hover .item.account .profile-circle .nav-logo-img {
  filter: brightness(125%);
}

nav .items.right .item.account .profile-circle .nav-logo-img {
  align-items: center;
  background-color: #181818;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  min-height: 100%;
  min-width: 100%;
}

.account .user-name {
  font-size: 13px;
  font-weight: 500;
  margin: auto 0;
  min-width: -moz-max-content;
  min-width: -webkit-max-content;
  min-width: max-content;
  padding: 6px 0 6px 10px;
  text-transform: uppercase;
}

.account .user-info {
  margin-right: 8px;
}

.nav-name,
.nav-mail {
  color: #fff;
  font-size: 12px;
  opacity: 0.9;
}

.nav-name {
  opacity: 1;
}

.nav-mail {
  font-size: 10px;
  margin: 2px 0 0 0;
  opacity: 0.5;
}

.nav-version {
  color: #fff;
  font-size: 10px;
  text-align: center;
  opacity: 0.5;
  width: 100%;
}

nav .drop-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  display: none;
  position: absolute;
  right: -1px;
  text-align: left;
  top: 45px;
  z-index: 99;
}

nav .drop-box.open {
  display: block;
}

nav .drop-box .scroll-container {
  overflow-y: auto;
  max-height: 65vh;
}

nav .drop-box .info {
  background-color: #282828;
  padding: 13px;
}

nav .drop-box .organisation {
  background-color: #242526;
  border-top: 1px solid black;
  cursor: pointer;
  display: flex;
  padding: 13px;
}

nav .drop-box .organisation:hover {
  background-color: #191a1b;
}

nav .drop-box .organisation.active {
  color: #0ade0a;
}

nav .drop-box .organisation p {
  font-size: 14px;
  margin: auto 6px auto 9px;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

nav .drop-box .organisation .nav-logo-img {
  align-items: center;
  background-color: #181818;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  min-height: 35px;
  max-height: 35px;
  min-width: 35px;
  max-width: 35px;
}

nav .drop-box .sign-out {
  text-align: left;
  text-transform: unset;
  margin: unset;
}

nav .drop-box .sign-out {
  background-color: #242526;
  border-top: 1px solid black;
  cursor: pointer;
  display: flex;
  padding: 13px;
  color: #fff;
}

nav .drop-box .sign-out p {
  font-size: 14px;
  margin: auto 6px auto 9px;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

nav .drop-box .sign-out:hover {
  background-color: #181818;
}

nav .drop-box .sign-out .logout-circle {
  background-image: url("../../assets/svgs/logout_white.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
  border-radius: 50%;
  cursor: pointer;
  height: 35px;
  margin: auto 0;
  overflow: hidden;
  position: relative;
  width: 35px;
}
