.access-rule-wrapper {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1550;
}

.access-rule-wrapper .inner {
  background-color: #181818;
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
}

.access-rule-wrapper .header {
  background-color: #272829;
  display: flex;
  padding: 15px 25px;
}

.access-rule-wrapper .header .group-header {
  display: flex;
  margin: auto 0;
  width: 100%;
}

.access-rule-wrapper .header .group-header .group-name {
  font-size: 15px;
  margin: auto 0;
}

.access-rule-wrapper .header .close {
  background-image: url(../../../assets/svgs/cross-light.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin: auto 0;
  height: 18px;
  opacity: 0.75;
  transition: 0.1s;
  width: 18px;
}

.access-rule-wrapper .header .close:hover {
  opacity: 1;
  transition: 0.1s;
}

.access-rule-wrapper {
  padding: 0 25px;
}

.access-rule-wrapper .checkbox-container {
  padding: 0px 0 0 19px;
}

.access-rule-wrapper input[type="date"] {
  width: calc(100% - 22px);
}

.access-rule-wrapper input[type="date"]:disabled {
  opacity: 0.5;
  transition: 0.2s;
}

.access-rule-wrapper input[type="time"] {
  width: calc(100% - 6px);
}

.access-rule-wrapper input[type="time"]:disabled {
  opacity: 0.5;
  transition: 0.2s;
}

.access-rule-wrapper .row {
  border-bottom: 1px solid black;
  display: flex;
  padding: 18px 25px;
  width: calc(100% - 50px);
}

.access-rule-wrapper .row.special {
  border-top: 1px solid #0a0a0a;
  background-color: #282828;
  padding: 10px 25px;
  width: calc(100% - 50px);
}

.access-rule-wrapper .row .col {
  width: 100%;
}

.access-rule-wrapper .row .flex {
  display: flex;
  margin: auto 0;
}

.access-rule-wrapper .row .arrow {
  background-image: url(../../../assets/svgs/arrow-light.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 18px;
  margin: auto 15px;
  min-height: 18px;
  min-width: 18px;
  width: 18px;
}

.access-rule-wrapper .row .flex .icon {
  background-color: #0a0a0a;
  background-image: url(../../../assets/svgs/groups.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 40px;
  height: 40px;
  margin: auto 8px auto 0;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
}

.access-rule-wrapper .row .flex .initials {
  background-color: #0a0a0a;
  border-radius: 40px;
  height: 40px;
  margin: auto 8px auto 0;
  min-height: 40px;
  min-width: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
}

.access-rule-wrapper .row .flex .icon.EDH {
  background-image: url(../../../assets/images/XS-EDH.png);
}

.access-rule-wrapper .row .flex .icon.EDC {
  background-image: url(../../../assets/images/XS-EDC.png);
}

.access-rule-wrapper .row .flex .icon.WMR {
  background-image: url(../../../assets/images/XS-WMR.png);
}

.access-rule-wrapper .row .flex .icon.PCB {
  background-image: url(../../../assets/images/XS-PCB.png);
  background-size: 80%;
}

.access-rule-wrapper .row .flex .name {
  font-size: 15px;
  margin: auto 0;
}

.access-rule-wrapper .row .day {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  height: 40px;
  line-height: 40px;
  margin: 0 5px;
  opacity: 0.5;
  text-transform: uppercase;
}

.access-rule-wrapper .row .day:hover {
  opacity: 1;
  transition: 0.1s;
}

.access-rule-wrapper .row .day.active {
  color: #0ade0a;
  opacity: 1;
  transition: 0.1s;
}

.access-rule-wrapper .row .text {
  color: #fff;
  font-size: 15px;
}

.access-rule-wrapper .row .small-text {
  color: rgba(255, 255, 255, 0.5);
  font-size: 13px;
  margin: 4px 0 0 0;
}

.access-rule-wrapper .buttons {
  display: flex;
  margin: 18px 15px 25px auto;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

.access-rule-wrapper .open-data {
  display: flex;
  margin: 15px 0 0 0;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.button-shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #0ade0a 4%, #d8fdd8 25%, #0ade0a 36%);
  background-size: 1000px 100%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
