.bar-container {
  height: 75px;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.bar-container .bar {
  background-color: #282828;
  position: relative;
  top: 25px;
  left: 10px;
  height: 25px;
  width: calc(100% - 20px);
  z-index: 0;
}

.bar-container .bar-overlay {
  background-color: #fff;
  cursor: pointer;
  opacity: 0.1;
  top: 25px;
  position: absolute;
  height: 25px;
  z-index: 1;
}

.bar-container .bar .time-dot {
  background-color: #fff;
  border-radius: 20px;
  height: 2px;
  width: 2px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}

.bar-container .bar .indicator-dot {
  background-color: #0ade0a;
  border-radius: 20px;
  height: 7px;
  width: 7px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}

.bar-container .current-time {
  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  top: 0px;
  z-index: 2;
}

.bar-container .current-time .pointer {
  background-color: #fff;
  opacity: 0.3;
  position: absolute;
  top: 25px;
  width: 2px;
  height: 25px;
}

.bar-container .current-time .arrow {
  border-radius: 20px;
  position: absolute;
  top: 50px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #fff;
}

.bar-container .selection-pointer {
  background-color: #fff;
  opacity: 0;
  position: absolute;
  opacity: 0;
  top: 25px;
  width: 1px;
  height: 25px;
}

.bar-container .selection-arrow {
  border-bottom: 5px solid #0ade0a;
  border-left: 5px solid transparent;
  border-radius: 20px;
  border-right: 5px solid transparent;
  height: 0;
  opacity: 0;
  position: absolute;
  top: 50px;
  width: 0;
}

.bar-container .range-selector {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  left: -4px;
  position: absolute;
  top: 0px;
  z-index: 1200;
}

.bar-container .range-selector .time {
  color: #fff;
  font-size: 12px;
  height: 25px;
  padding-right: 5px;
  text-align: right;
}

.bar-container .range-selector .pointer {
  background-color: #5d5d5d;
  cursor: ew-resize;
  height: 25px;
  width: 10px;
}

.bar-container .range-selector .pointer:hover {
  background-color: #5d5d5d;
  filter: brightness(125%);
}

.bar-container .lograngeselector-right .pointer {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin: 0 0 0 5px;
}

.bar-container .lograngeselector-right .range-selector .time {
  color: #fff;
  font-size: 12px;
  height: 25px;
  margin-right: 10px;
  transform: translateX(-20px);
}

.bar-container .lograngeselector-left .pointer {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin: 0 0 0 -5px;
}

.bar-container .time-start {
  color: #fff;
  font-size: 12px;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 100%;
  transform: translateY(-100%);
}

.bar-container .time-middle {
  color: #fff;
  font-size: 12px;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 100%;
  transform: translateY(-100%);
}
