.bridges {
  box-sizing: content-box;
  width: 100%;
}

.bridges .inner .list-item .info.bridge-icon {
  min-width: 50px;
  width: unset;
}

.bridges .inner .list-item .info.status {
  align-items: center;
  min-width: 60px;
  width: unset;
}

.bridges .inner .list-item .info.status .online {
  background-size: 15px;
  background-position: center;
  background-color: #0ade0a;
  border-radius: 15px;
  height: 15px;
  margin: 0 auto;
  width: 15px;
}

.bridges .inner .list-item .info.status .offline {
  background-size: 10px;
  background-position: center;
  background-color: #ed1c24;
  background-repeat: no-repeat;
  border-radius: 15px;
  min-height: 15px;
  margin: 0 auto;
  min-width: 15px;
}

.bridges .inner .list-item .info.member {
  min-width: 55px;
  width: unset;
}

.bridges .inner .legend-bar .bridge-icon {
  min-width: 50px;
  width: unset;
}

.bridges .inner .legend-bar .delete {
  min-width: 60px;
  width: unset;
}

.bridges .inner .legend-bar .members {
  min-width: 65px;
  margin-left: 5px;
  width: unset;
}

.bridges .inner .legend-bar .role {
  min-width: 100px;
  width: 100%;
}

.nextxs-list .list-item .members {
  display: flex;
  color: #fff;
  margin: auto 0;
  font-size: 14px;
}

.nextxs-list .list-item .info button.edit {
  background-color: transparent;
  background-image: url(../../assets/svgs/edit-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  min-height: 14px;
  margin: 0 auto;
  opacity: 0.7;
  min-width: 14px;
}

.nextxs-list .list-item .info:hover button.edit {
  background-image: url(../../assets/svgs/edit-green.svg);
  opacity: 1;
}

.title-bar {
  display: flex;
}

.title-bar h2 {
  width: 100%;
}

.title-bar .button {
  min-width: max-content;
  margin: auto 0;
}
