.groups {
  box-sizing: content-box;
  width: 100%;
}

.groups .inner .list-item .info.user-icon {
  min-width: 50px;
  width: unset;
}

.groups .inner .list-item .info.delete {
  min-width: 60px;
  width: unset;
}

.groups .inner .list-item .info.member {
  min-width: 55px;
  width: unset;
}

.groups .inner .legend-bar .user-icon {
  min-width: 50px;
  width: unset;
}

.groups .inner .legend-bar .delete {
  min-width: 60px;
  width: unset;
}

.groups .inner .legend-bar .members {
  min-width: 65px;
  margin-left: 5px;
  width: unset;
}

.groups .inner .legend-bar .role {
  min-width: 100px;
  width: 100%;
}

.nextxs-list .list-item .members {
  display: flex;
  color: #fff;
  margin: auto 0;
  font-size: 14px;
}

.nextxs-list .list-item .info button.delete {
  background-color: transparent;
  background-image: url(../../assets/svgs/edit-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  min-height: 14px;
  margin: 0 auto;
  opacity: 0.7;
  min-width: 14px;
}

.nextxs-list .list-item .info:hover button.delete {
  background-image: url(../../assets/svgs/edit-green.svg);
  opacity: 1;
}

.nextxs-list .list-item .members button.edit {
  background-color: transparent;
  background-image: url(../../assets/svgs/edit-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  min-height: 14px;
  margin: 0 auto;
  opacity: 0.7;
  min-width: 14px;
}

.nextxs-list .list-item .members:hover button.edit {
  background-image: url(../../assets/svgs/edit-green.svg);
  opacity: 1;
}

.nextxs-list .list-item .members .count {
  color: #fff;
}

.nextxs-list .list-item .members:hover .count {
  color: #0ade0a;
}

.title-bar {
  display: flex;
}

.title-bar h2 {
  width: 100%;
}

.title-bar .button {
  min-width: max-content;
  margin: auto 0;
}
