.unlocks {
  box-sizing: content-box;
  min-height: calc(100vh - 82px);
  width: 100%;
}

.unlocks .inner .user-icon,
.unlocks .inner .lock-icon {
  min-width: 50px;
  width: unset;
}

.unlocks .inner .year,
.unlocks .inner .time {
  min-width: 50px;
  width: unset;
}

.unlocks .inner .date {
  min-width: 100px;
  width: unset;
}

.unlocks .date-range-field {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  cursor: pointer;
  display: block;
  height: 19px;
  padding: 6px;
  margin: 1px 0 0 0;
  width: 100%;
}

/* .unlocks .inner .time {
  padding: 0 25px 0 0;
} */

.unlocks .inner .date .name,
.unlocks .inner .year .name,
.unlocks .inner .time .name {
  width: 100%;
}

.unlocks .inner .arrow {
  min-width: max-content;
  margin: 5px 10px 0 0;
  width: unset;
}

.unlocks .inner .arrow .door-icon {
  background-image: url(../../assets/svgs/door-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  min-height: 20px;
  min-width: 20px;
}

.unlocks .inner .arrow .name {
  text-align: center;
  width: 100%;
}

.unlocks .inner .user-name {
  min-width: max-content;
}

.action-container {
  display: flex;
  padding: 0px 0px 0px 6px;
  margin: 11px 0 0 0;
  width: 25%;
}

.date-range-container {
  display: flex;
  padding: 0px 0px 0px 6px;
  margin: 11px 0 0 0;
}

.date-range-container-small {
  display: flex;
  padding: 0px 0px 0px 0px;
  margin: 11px 0 0 0;
}

.action-container .selector {
  box-sizing: border-box;
}

.time-container {
  display: flex;
  padding: 0px 0px 0px 6px;
  width: 50%;
}

.page-inner .actions .unlocks-search-bar {
  background-color: #fff;
  border-radius: 3px;
  display: flex;
  padding: 6px 9px 5px 6px;
  margin: 25px 0 0px 0;
  width: 100%;
}

.page-inner .actions .unlocks-search-bar .icon {
  background-image: url(../../assets/svgs/search-dark.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 20px;
  margin: 2px 4px 0 0;
  transition: 0.2s;
  width: 21px;
}

.page-inner .actions .unlocks-search-bar input {
  border: none;
  font-size: 14px;
  padding: 0;
  margin: auto 0;
  width: 100%;
}

.unlocks.page .page-inner .list-item .info.time,
.unlocks.page .page-inner .legend-bar .legend-item.time {
  min-width: 100px;
}

.unlocks.page .page-inner .list-item .info.date,
.unlocks.page .page-inner .legend-bar .legend-item.date {
  width: 100%;
}

.unlocks.page .page-inner .list-item .info.arrow,
.unlocks.page .page-inner .legend-bar .legend-item.arrow {
  width: 100px;
}
