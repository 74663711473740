input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: rgba(39, 40, 41, 0.9) !important;
  color: #fff !important;
}

.sign-in {
  margin: 0;
  min-height: 100vh;
  width: 100%;
}

.sign-in .inner {
  background-color: #181818;
  box-sizing: content-box;
  left: 50%;
  max-width: 250px;
  padding: 50px 50px 20px 50px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.sign-in .inner .container {
  background-color: #181818;
}

.sign-in .inner .logo {
  background-image: url("../../assets/svgs/logo-light.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 65px;
  margin: 0 auto 25px auto;
  width: 133px;
}

.sign-in .inner form {
  width: 100%;
}

.login-button {
  background-color: rgba(10, 222, 10, 0.8);
  border-radius: 20px;
  border: 1px solid #0ade0a;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  height: max-content;
  margin: auto 10px auto 0;
  padding: 9px 15px 8px 15px;
  text-align: center;
  text-transform: uppercase;
  width: -moz-max-content;
  width: -webkit-max-content;
  width: max-content;
}

.login-button:hover {
  background-color: rgba(10, 222, 10, 1);
}

.sign-in .inner form .row {
  margin: 15px 0 0 0;
  width: 100%;
}

.sign-in .inner form input {
  border: none;
  border-radius: 3px;
  color: #181818;
  font-size: 15px;
  padding: 12px;
  width: calc(100% - 24px);
}

.sign-in .inner form input:focus {
  opacity: 1;
}

.sign-in .inner form .row.submit {
  margin: 20px auto 0 auto;
  text-align: center;
}

.sign-in .inner form .row.submit div {
  margin: 0 auto;
  width: max-content;
  width: -moz-max-content;
  width: -webkit-max-content;
}

.sign-in p.small {
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  margin: 5px 0;
  text-align: right;
}

.sign-in p.small:hover {
  color: #0ade0a;
  text-decoration: underline;
}

.sign-in #login-error {
  color: red;
  font-size: 14px;
  text-align: center;
  min-height: 22.65px;
}
