.separator {
  background-color: rgba(255, 255, 255, 0.12);
  height: 1px;
  width: 100%;
}

.group-info .photo {
  background-color: #0f0f0f;
  background-image: url("../../assets/svgs/groups.svg");
}

.group-actions .info-row {
  display: flex;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 15px 0;
}

.group-actions .info-row-static {
  display: flex;
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
  padding: 15px 0;
}

.group-actions .info-row:hover {
  background-color: rgba(39, 40, 41, 0.9);
}

.group-actions .info-row .title,
.group-actions .info-row-static .title {
  font-size: 14px;
  margin: auto 0 auto 25px;
  width: 100%;
}

.group-actions .info-row .title.child {
  font-size: 14px;
  margin: auto 0 auto 40px;
  width: 100%;
}

.info-row .download {
  background-image: url(../../assets/svgs/download.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-height: 22px;
  margin: 0 25px 0 0;
  opacity: 0.3;
  min-width: 22px;
}

.info-row:hover .download {
  background-image: url(../../assets/svgs/download-green.svg);
  opacity: 1;
}

.info-row .pencil {
  background-image: url(../../assets/svgs/edit-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-height: 22px;
  margin: 0 25px 0 0;
  opacity: 0.3;
  min-width: 22px;
}

.info-row:hover .pencil {
  background-image: url(../../assets/svgs/edit-green.svg);
  opacity: 1;
}

.info-row .arrow {
  background-image: url(../../assets/svgs/arrow-right-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-height: 22px;
  margin: 0 25px 0 0;
  opacity: 0.3;
  min-width: 22px;
}

.info-row:hover .arrow {
  background-image: url(../../assets/svgs/arrow-right-green.svg);
  opacity: 1;
}

.info-row .bin {
  background-image: url(../../assets/svgs/delete-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: transparent;
  border: none;
  cursor: pointer;
  min-height: 22px;
  margin: 0 25px 0 0;
  opacity: 0.3;
  min-width: 22px;
}

.info-row:hover .bin {
  background-image: url(../../assets/svgs/delete-red.svg);
  opacity: 1;
}
