.hardware-image {
  background-color: #0f0f0f;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 150px;
  height: 150px;
  margin: 0 auto;
  transition: 0.2s;
  width: 150px;
}

.small-image {
  border-radius: 75px;
  height: 75px;
  width: 75px;
  margin: 0;
}

.hardware-image.EDH {
  background-image: url(../../assets/images/XS-EDH.png);
}

.hardware-imagee.EDC {
  background-image: url(../../assets/images/XS-EDC.png);
}

.hardware-image.WMR {
  background-image: url(../../assets/images/XS-WMR.png);
}

.hardware-image.PCB {
  background-image: url(../../assets/images/XS-PCB.png);
  background-size: 80%;
}
