.users {
  box-sizing: content-box;
  width: 100%;
}

.users .inner .list-item .info.user-icon {
  min-width: 50px;
  width: unset;
}

.users .inner .list-item .info.edit {
  align-items: center;
  min-width: 60px;
  width: unset;
}

.users .inner .legend-bar .user-icon {
  min-width: 50px;
  width: unset;
}

.users .inner .legend-bar .delete {
  min-width: 60px;
  width: unset;
}

.users .inner .legend-bar .role {
  min-width: 100px;
  width: 100%;
}

.nextxs-list .list-item .info button.edit {
  background-color: transparent;
  background-image: url(../../assets/svgs/edit-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border: none;
  cursor: pointer;
  height: 12px;
  margin: 0 auto;
  opacity: 0.7;
  width: 12px;
}

.nextxs-list .list-item .info:hover button.edit {
  background-image: url(../../assets/svgs/edit-green.svg);
  opacity: 1;
}

.title-bar {
  display: flex;
}

.title-bar h2 {
  width: 100%;
}

.title-bar .button {
  min-width: max-content;
  margin: auto 0;
}
