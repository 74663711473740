.access-matrix-wrapper {
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1500;
}
.access-matrix-wrapper .container {
  background-color: #181818;
  color: #fff;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: calc(100vw - 150px);
}

.access-matrix-wrapper .container .header {
  background-color: #272829;
  border-right: 1px solid black;
  display: flex;
  padding: 10px 15px;
}

.access-matrix-wrapper .container .header .group-header {
  display: flex;
  margin: auto 0;
  width: 100%;
}

.access-matrix-wrapper .container .header .group-header .group-icon {
  background-color: #0a0a0a;
  background-image: url("../../assets/svgs/groups.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin: auto 10px auto 0;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
}

.access-matrix-wrapper .container .header .group-header .user-icon {
  background-color: #0a0a0a;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  font-weight: 500;
  line-height: 40px;
  margin: auto 10px auto 0;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
  text-align: center;
}

.access-matrix-wrapper .container .header .group-header .group-name {
  margin: auto 0;
  margin-left: 8px;
}

.access-matrix-wrapper .container .header .close {
  background-image: url(../../assets/svgs/cross-light.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  margin: auto 8px auto 0;
  height: 18px;
  opacity: 0.75;
  transition: 0.1s;
  width: 18px;
}

.access-matrix-wrapper .container .header .close:hover {
  opacity: 1;
  transition: 0.1s;
}

.access-matrix-wrapper .container .next,
.access-matrix-wrapper .container .prev {
  background-color: #282828;
  border-radius: 35px;
  cursor: pointer;
  height: 35px;
  position: absolute;
  top: 75px;
  width: 35px;
  z-index: 124;
}

.access-matrix-wrapper .container .next:hover,
.access-matrix-wrapper .container .prev:hover {
  background-color: #0967de;
  transition: 0.1s;
}

.access-matrix-wrapper .container .prev .arrow,
.access-matrix-wrapper .container .next .arrow {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.access-matrix-wrapper .container .next {
  right: -40px;
}

.access-matrix-wrapper .container .prev {
  left: -40px;
}

.access-matrix-wrapper .container .access-rules-grid {
  background-color: #181818;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  width: 100%;
}

.access-matrix-wrapper .container .access-rules-grid .col {
  display: inline-block;
  min-width: 260px;
  width: calc(100% / 4);
}

.access-matrix-wrapper .container .access-rules-grid .col .cell {
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  display: flex;
  height: 40px;
  padding: 10px 15px;
  box-sizing: content-box;
  min-width: 230px;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell:first-child:hover,
.access-matrix-wrapper .container .access-rules-grid .col .cell:first-child {
  background-color: #282828;
  border-top: 1px solid black;
  cursor: default;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell:hover {
  background-color: rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: 0.1s;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell.inactive:hover {
  background-color: inherit;
  cursor: default;
}

.access-matrix-wrapper .container .access-rules-grid::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}
.access-matrix-wrapper .container .access-rules-grid::-webkit-scrollbar-thumb {
  background: #282828;
  border-radius: 10px;
}
.access-matrix-wrapper .container .access-rules-grid::-webkit-scrollbar-track {
  background: transparent;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .lock-icon {
  background-color: #0a0a0a;
  background-image: url("../../assets/images/XS-EDH.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  margin: auto 10px auto 0;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .lock-icon-shape {
  background-color: #0a0a0a;
  border-radius: 50%;
  margin: auto 10px auto 0;
  max-height: 40px;
  max-width: 40px;
  min-height: 40px;
  min-width: 40px;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .lock-icon.EDH {
  background-image: url(../../assets/images/XS-EDH.png);
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .lock-icon.EDC {
  background-image: url(../../assets/images/XS-EDC.png);
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .lock-icon.WMR {
  background-image: url(../../assets/images/XS-WMR.png);
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .lock-icon.PCB {
  background-image: url(../../assets/images/XS-PCB.png);
  background-size: 80%;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .lock-name {
  margin: auto 0;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .lock-name-shape {
  border-radius: 50px;
  height: 12px;
  margin: auto 0;
  width: 128px;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .left {
  width: 100%;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-dates {
  display: flex;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-dates
  .date {
  font-size: 12px;
  text-transform: uppercase;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-dates
  .access-date-shape {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  margin: 5px 0 0 0;
  height: 12px;
  width: 168px;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-dates
  .arrow {
  background-image: url("../../assets/svgs/arrow-light.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  margin: auto 10px;
  height: 11px;
  opacity: 0.8;
  width: 11px;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-days {
  display: flex;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-days
  .access-day {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 600;
  margin: 5px 6px 0 0;
  text-transform: uppercase;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-days
  .access-day-shape {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  margin: 5px 0 0 0;
  height: 12px;
  width: 144px;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .left
  .access-days
  .access-day.active {
  color: #0ade0a;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .right {
  font-size: 12px;
  margin: auto 0 auto 10px;
  min-width: -moz-max-content;
  min-width: -webkit-max-content;
  min-width: max-content;
  text-align: right;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .right
  .time-shape {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  height: 12px;
  width: 72px;
}

.access-matrix-wrapper .container .access-rules-grid .col .cell .add-rule {
  font-size: 30px;
  margin: auto 0;
  opacity: 0.75;
  text-align: center;
  transition: 0.1s;
  width: 100%;
}

.access-matrix-wrapper
  .container
  .access-rules-grid
  .col
  .cell
  .add-rule:hover {
  opacity: 1;
  transition: 0.1s;
}

.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, #0a0a0a 4%, #1f1f1f 25%, #0a0a0a 36%);
  background-size: 1000px 100%;
}

.dimmed {
  opacity: 0.5;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
